module.exports = {
  pathPrefix: '/', // Prefix for all links. If you deploy your site to example.com/portfolio your pathPrefix should be "portfolio"
  title: 'UberGamerMonkey.com', // Navigation and Site Title
  titleAlt: 'UberGamerMonkey.com', // Title for JSONLD
  description: 'Life is played, one game at a time.',
  url: 'https://www.ubergamermonkey.com', // Domain of your site. No trailing slash!
  siteUrl: 'https://www.ubergamermonkey.com', // url + pathPrefix
  siteLanguage: 'en', // Language Tag on <html> element
  siteTitle: 'UberGamerMonkey.com',
  logo: '/logo/logo.png', // Used for SEO
  banner: '/logo/banner.png',
  // JSONLD / Manifest
  favicon: 'static/logo/logo.png', // Used for manifest favicon generation
  shortName: 'UbrGmrMnky', // shortname for manifest. MUST be shorter than 12 characters
  author: 'Casey', // Author for schemaORGJSONLD
  themeColor: '#1080d0',
  backgroundColor: '#141618',
  twitter: '@ElementCy', // Twitter Username
};
