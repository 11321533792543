import React from 'react';
import styled from '@emotion/styled';
import { StaticQuery, graphql, Link } from 'gatsby';
import { kebabCase } from 'lodash';

const TagCloudContainer = styled.div`
margin: 1rem 0;
display: flex;
flex-direction: row;
flex-wrap: wrap;
justify-content: center;
font-size: 11px !important;
text-transform: uppercase;
a {
  margin: 0 1rem 1rem 0;
  color: ${props => props.theme.colors.white.light};
  padding: 0.3rem 0.6rem;
  background: ${props => props.theme.colors.background.dark};
  border-radius: 4px;
  border: 1px solid;
  border-color: ${props => props.theme.colors.background.light};
  &:hover {
    color: ${props => props.theme.colors.linkHover};
  }
}
`;

const TagCloud = ({ data }) => (
  <StaticQuery
    query={query}
    render={data => {
        return (
          <TagCloudContainer>
            {data && data.allMarkdownRemark.group.map( tag => {
                  const upperTag = tag.fieldValue.charAt(0).toUpperCase() + tag.fieldValue.slice(1);
                  const lowerTag = tag.fieldValue.toLowerCase();
                  return (
                    <Link key={tag.fieldValue} to={`/tags/${kebabCase(lowerTag)}`}>
                      <span>{upperTag}</span>
                    </Link>
                  );
                }
              )
            }
          </TagCloudContainer>
        );
      }
  } />
);

export default TagCloud;

const query = graphql`
  query {
    allMarkdownRemark(limit: 50) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`;