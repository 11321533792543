import React from 'react';
import styled from '@emotion/styled';
import { SocialIcon } from 'react-social-icons';
import theme from '../../config/theme';
import { TagCloud } from 'components';

const Wrapper = styled.footer`
  position: relative;
  padding-top: 2rem;
  bottom: 0;
  box-shadow: ${props => props.theme.shadow.footer};
  background: ${props => props.theme.colors.background.medium};
  font-family: ${props => props.theme.fontFamily.body};
  font-weight: 500;
  @media (max-width: ${props => props.theme.breakpoints.s}) {
    padding-top: 7rem;
  }
`;

const MainFooter = styled.section`
  background: ${props => props.theme.colors.background.medium};
  color:  ${props => props.theme.colors.white.light};
  padding: 1.25rem 1.875rem;
  display: flex;
  flex-wrap: wrap;
  @media only screen and (min-width: 29.8125rem /*477px*/) {
    justify-content: space-around;
  }
  @media only screen and (min-width: 77.5rem /*1240px*/ ) {
    justify-content: space-evenly;
  }
`;

const FooterWidgetTitle = styled.h3`
  border: 0;
  padding: 0;
  font-family: ${props => props.theme.fontFamily.footer};
  letter-spacing: .05em;
  text-transform: uppercase;
  font-size: 0.775em;
  word-break: break-word;
  margin: 0px 0px 27px 0px;
`;

const FooterWidget = styled.div`
  font-size: 0.775em;
`;

const MainFooterItem = styled.div`
  padding: 1.25rem;
  min-width: 12.5rem /*200px*/;
  max-width: 25%;
`;

const SocialFooter = styled.section`
  background: ${props => props.theme.colors.background.dark};
  padding: 0 1.875rem 1.25rem;
`;

const LegalFooter = styled.section`
  background: ${props => props.theme.colors.background.dark};
  margin: 0;
  padding-bottom: 2rem;
  text-align: center;
  color: ${props => props.theme.colors.white.light};
  font-size: 0.675em;
`;

const SocialList = styled.ul`
  list-style: none;
  padding-left: 0;
  display: flex;
  justify-content: center;
  border-top: 1px #777 solid; 
  padding-top: 1.25rem;
  margin-bottom: 0;
  svg.social-svg {
      &:hover *.social-svg-icon {
        fill: ${props => props.theme.colors.primary.base} !important;
      }
  }
  li {
    margin: 0.5rem;
    font-size: 1.25rem;
  }
`;

const Footer = () => (
  <Wrapper>
    <MainFooter>
      <MainFooterItem>
        <FooterWidgetTitle>About Me</FooterWidgetTitle>
        <FooterWidget>I write code for a living. I also enjoy games (making and playing), music (listening and producing), and a few other things.</FooterWidget>
      </MainFooterItem>
      <MainFooterItem>
        <FooterWidgetTitle>Tags</FooterWidgetTitle>
        <TagCloud />
      </MainFooterItem>
    </MainFooter>
    <SocialFooter>
      <SocialList>
        <li><SocialIcon url="https://www.facebook.com/ElementCy/" fgColor={theme.colors.white.light} bgColor={theme.colors.background.dark} /></li>
        <li><SocialIcon url="https://twitter.com/ElementCy/" fgColor={theme.colors.white.light} bgColor={theme.colors.background.dark} /></li>
        <li><SocialIcon url="https://github.com/ElementCy/" fgColor={theme.colors.white.light} bgColor={theme.colors.background.dark} /></li>
        <li><SocialIcon url="https://youtube.com/user/ElementCy/" fgColor={theme.colors.white.light} bgColor={theme.colors.background.dark} /></li>
      </SocialList>
    </SocialFooter>
    <LegalFooter>
      <span>
        © {new Date().getFullYear()} UberGamerMonkey.com. All rights reserved
      </span>
    </LegalFooter>
  </Wrapper>
);
export default Footer;
